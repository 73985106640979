<template>
    <div class="k-line-chart-container">        
        <h3 class="k-line-chart-title" @click="">{{title}}</h3>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Layout',
        props: {
            title: String
        }
    }
</script>
